<script>
import {ref} from 'vue';
import axios from 'axios';
import Vditor from 'vditor';
import 'vditor/dist/index.css';

const vditor = ref(null);

export default {
  data() {
    return {
      data: ref([]),  // 初始化数据
    };
  },
  methods: {
    fetchData(id) {
      axios.get('https://www.ymshici.com/api/tech/v1.0/markdown?id=' + id)
          .then(response => {
            this.data = response.data;  // 将获取的数据存储在 data 属性中
            vditor.value.setValue(response.data.markdown);
            document.title = response.data.title + "_乐享推荐";
            // vditor.value.disabled();
          })
          .catch(error => {
            console.error('There was an error!', error);
          });
    }
  },
  mounted() {
    vditor.value = new Vditor('vditor', {
      mode: 'ir',
      toolbarConfig: {
        hide: true // 隐藏工具栏
      },
      cache: {
        enable: false,
      },
      preview: {
        markdown: {
          toc: true
        }
      },
      after: () => {
        this.fetchData(this.$route.params.id);
      },
    });
    // 在组件挂载后获取数据
  }
};


</script>

<template>
  <h1>{{ data.title }}<a :href='`https://ymshici.com/tech/${data.id}.html`'>#</a></h1>
  <span class="r5px" v-for="(name, index2) in data.tags" :key="index2">
    <router-link :to="`/tech/tag/${name}`" target="_blank">{{ name }}</router-link>
  </span> &nbsp;
  <div id="vditor"></div>
</template>

<style scoped>
h1 {
  padding: .6em 0 0 0;
  font-size: 1.8em;
}


#vditor {
  border: 0px;
  margin-bottom: 30px;
}


</style>
