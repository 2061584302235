<script>
import axios from 'axios';
import {ref} from 'vue';

export default {
  data() {
    return {
      data: ref([]),  // 初始化数据
      query: ref(this.$route.params.name)
    };
  },
    // 组件内的守卫可以在参数改变时重新获取数据
  beforeRouteUpdate(to, from, next) {
    // 参数改变时，例如从 /user/1 到 /user/2
    console.log('参数更新了:', to.params.name);
    this.fetchData(to.params.name);
    next();
  },
  methods: {
    fetchData(name) {
      axios.get('https://www.ymshici.com/api/tech/query?name='+name)
          .then(response => {
            this.data = response.data;  // 将获取的数据存储在 data 属性中
            document.title = name + "_乐享推荐";
          })
          .catch(error => {
            console.error('There was an error!', error);
          });
    }
  },
  mounted() {
    this.fetchData(this.$route.params.name);  // 在组件挂载后获取数据
  }
};
</script>

<template>
  <h1>{{query}}</h1>
  <ul class="saying-ul">
    <li v-for="(doc, index) in data.docs" :key="index">
      <h2>
        <router-link :to="`/tech/${doc.id}`" target="_blank">{{ doc.title }}</router-link>
      </h2>
      <span class="r5px" v-for="(name, index2) in doc.tags" :key="index2">
        <router-link :to="`/tech/tag/${name}`" target="_blank">{{ name }}</router-link>
      </span>
      <div>{{ doc.content }}</div>
    </li>
  </ul>
</template>

<style scoped>
@import '@/css/main.css';
</style>