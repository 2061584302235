<script>
import {ref} from 'vue';
import axios from 'axios';
import Vditor from 'vditor';
import 'vditor/dist/index.css';

const vditor = ref(null);

export default {
  data() {
    return {
      data: ref([]),  // 初始化数据
    };
  },
  methods: {
    fetchData(id) {
      axios.get('https://www.ymshici.com/api/poem/get?id=' + id)
          .then(response => {
            this.data = response.data.doc;  // 将获取的数据存储在 data 属性中
            vditor.value.setValue(response.data.doc.content);
            document.title = response.data.doc.title + "_乐享推荐";
          })
          .catch(error => {
            console.error('There was an error!', error);
          });
    }
  },
  mounted() {
    vditor.value = new Vditor('vditor', {
      mode: 'ir',
      toolbarConfig: {
        hide: true // 隐藏工具栏
      },
      cache: {
        enable: false,
      },
      preview: {
        markdown: {
          toc: true
        }
      },
      after: () => {
        this.fetchData(this.$route.params.id);
      },
    });
  }
};


</script>

<template>
  <h1>{{data.author}}《{{ data.title }}》</h1>
  <div id="vditor"></div>
</template>

<style scoped>
h1 {
  padding: .6em 0 0 0;
  font-size: 1.8em;
}


#vditor {
  border: 0;
  margin-bottom: 30px;
}


</style>
