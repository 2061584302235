import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import AppNavbar from './views/main/AppNavbar.vue';
import { BootstrapVueNext } from 'bootstrap-vue-next';

// import 'element-plus/dist/index.css';
// import ElementPlus from 'element-plus';
// import {ElForm, ElFormItem, ElInput, ElButton} from 'element-plus';


// 引入 Bootstrap 和 BootstrapVue 的 CSS 文件
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// const cors = require('cors');
// const express = require('express');
// const app = express();



const app = createApp(App);


// app.use(cors({
//   origin: ['https://irec.fun', 'https://ymshici.com'], // 允许这些域名的跨域请求
// }));

app.component('AppNavbar', AppNavbar);
app.use(router);
// 使用 BootstrapVueNext
app.use(BootstrapVueNext);
// app.use(ElementPlus);
// app.component(ElButton.name, ElButton)
// app.component(ElForm.name, ElForm)
// app.component(ElFormItem.name, ElFormItem)
// app.component(ElInput.name, ElInput)




router.afterEach((to) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
});

app.mount('#app');