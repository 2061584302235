<script>
import {ref} from 'vue';
import {useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import Vditor from 'vditor';
import 'vditor/dist/index.css';

const vditor = ref(null);

export default {
  data() {
    return {
      data: ref([]),  // 初始化数据
    };
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const text = ref(''); // 使用ref创建响应式数据
    const handleSubmit = async (event) => {
      event.preventDefault();
      try {
        const response = await axios.post('https://www.ymshici.com/api/tech/markdown/edit', {
          id: route.params.id,
          passwd: text.value,
          content: vditor.value.getValue(),
        });
        // 处理响应
        console.log(response.data);
        router.push('/tech/' + route.params.id);
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    };
    return {
      text,
      handleSubmit
    }
  },
  methods: {
    fetchData(id) {
      axios.get('https://www.ymshici.com/api/tech/v1.0/markdown?id=' + id)
          .then(response => {
            this.data = response.data;  // 将获取的数据存储在 data 属性中
            vditor.value.setValue(response.data.markdown);
          })
          .catch(error => {
            console.error('There was an error!', error);
          });
    }
  },
  mounted() {
    vditor.value = new Vditor('vditor', {
      mode: 'ir',
      toolbarConfig: {
        pin: true,
      },
      cache: {
        enable: false,
      },
      after: () => {
        this.fetchData(this.$route.params.id);
        //
      },
    });
    // 在组件挂载后获取数据
  }
};


</script>

<template>
  <h1>{{ data.title }}<a :href='`/tech/${data.id}`'>#</a></h1>
  <div id="vditor"></div>
  <input v-model="text">
  <button @click="handleSubmit">提交</button>
</template>

<style scoped>

</style>