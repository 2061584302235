<script setup>

</script>

<template>
  <ul class="saying-ul">
    <li>
      <router-link :to="`/doc`">订阅</router-link>
    </li>
    <li>
      <router-link :to="`/efficiency`">效率</router-link>
    </li>
    <li>
      <router-link :to="`/saying`">格言</router-link>
    </li>
  </ul>
</template>

<style scoped>

</style>